import React from 'react';

import './index.scss';
import { Container } from './Container';
import logo from '../images/logo.svg';

import * as smoothscroll from 'smoothscroll-polyfill';

interface Props {
  children: React.ReactNode;
}

export function Layout({ children }: Props): JSX.Element {
  return (
    <>
      <nav className="menu">
        <div className="container">
          <a className="menu__logo" href="/">
            <img src={logo} alt="Home" />
          </a>
          <input className="menu__switch" id="menu-switch" type="checkbox" />
          <label className="menu__toggle" htmlFor="menu-switch" />
          <div className="menu__content">
            <div className="menu__items">
              <a className="menu__item" href="/#services">
                Services
              </a>
              <a className="menu__item" href="/#jobs">
                Jobs
              </a>
              <a className="menu__item" href="/handbook">
                Handbook
              </a>
              <a className="menu__item" href="/#office">
                Office
              </a>
              <a className="menu__item" href="/#contact">
                Contact
              </a>
            </div>
          </div>
        </div>
      </nav>

      {children}
      <footer className="footer">
        <Container>
          <a href="/imprint" className="footer__link">
            Imprint
          </a>
          <a href="/privacy" className="footer__link">
            Privacy
          </a>
        </Container>
      </footer>
    </>
  );
}

if (typeof window !== 'undefined') {
  smoothscroll.polyfill();

  document.addEventListener('click', (event) => {
    const link = (event.target as HTMLElement).closest('a');
    if (!link) {
      return;
    }
    const pathname = link.pathname,
      anchor = link.hash;
    if (link.host !== location.host) {
      return;
    }
    if (pathname === location.pathname) {
      event.preventDefault();
      if (anchor && anchor.length) {
        const element = document.querySelector(anchor);
        if (!element) {
          return;
        }
        const top = element.getBoundingClientRect().top - 80;
        window.scrollBy({ top, behavior: 'smooth' });
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }

      const menuSwitch = document.querySelector('#menu-switch');
      if (menuSwitch) {
        (menuSwitch as HTMLInputElement).checked = false;
      }
    }
  });
}
